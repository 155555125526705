var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stockBar"},[_c('div',{staticClass:"stockBarLeft"},[_c('div',{staticClass:"symbolPairChoose",on:{"click":_vm.SPCselect}},[_vm._m(0),_c('div',{staticClass:"symbolPairIcon"},[_c('span',{staticClass:"mr5"},[_vm._v("交易对")]),(_vm.isSelect)?_c('Icon',{attrs:{"type":"md-arrow-dropup"}}):_c('Icon',{attrs:{"type":"md-arrow-dropdown"}})],1)]),(_vm.isSelect)?_c('div',{staticClass:"SPCcomplex"},[_c('div',{staticClass:"symbolPairChoose"},[_vm._m(1),_c('div',{staticClass:"symbolPairIcon"},[_c('span',{staticClass:"mr5"},[_vm._v("$1,920.0")]),(true)?_c('span',{staticClass:"red"},[_c('Icon',{attrs:{"type":"md-arrow-dropdown"}}),_vm._v(" -4.89%")],1):_c('span',{staticClass:"green"},[_c('Icon',{attrs:{"type":"md-arrow-dropup"}}),_vm._v(" 4.89%")],1)])]),_vm._l((10),function(item,index){return _c('div',{key:index,staticClass:"symbolPairChoose boderB"},[_vm._m(2,true),_c('div',{staticClass:"symbolPairIcon"},[_c('span',{staticClass:"mr5"},[_vm._v("$1,920.0")]),(true)?_c('span',{staticClass:"green"},[_c('Icon',{attrs:{"type":"md-arrow-dropdown"}}),_vm._v(" 4.89%")],1):_c('span',{staticClass:"red"},[_c('Icon',{attrs:{"type":"md-arrow-dropup"}}),_vm._v(" -4.89%")],1)])])})],2):_vm._e()]),_c('div',{staticClass:"stockBarRight"},[_c('div',{staticClass:"stockPrice green"},[_vm._v("$2,407.2")]),_c('ul',{staticClass:"pairInfo"},[_vm._m(3),_vm._m(4),_c('li',[_c('div',[_c('span',[_vm._v("24小时变动")]),_c('p',{staticClass:"red"},[_c('Icon',{attrs:{"type":"md-arrow-dropdown"}}),_vm._v("$2,406.75 (20%)")],1)])]),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"symbolPair"},[_c('img',{attrs:{"src":require("@/assets/img/logos/eth.svg")}}),_c('span',{staticClass:"ml5"},[_vm._v("ETH - DAI")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"symbolPair"},[_c('img',{attrs:{"src":require("@/assets/img/logos/eth.svg")}}),_c('span',{staticClass:"ml5"},[_vm._v("ETH - DAI")]),_c('span',{staticClass:"tag f12 ml5"},[_vm._v("ETH")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"symbolPair"},[_c('img',{attrs:{"src":require("@/assets/img/logos/eth.svg")}}),_c('span',{staticClass:"ml5"},[_vm._v("ETH - DAI")]),_c('span',{staticClass:"tag f12 ml5"},[_vm._v("ETH")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',[_c('span',[_vm._v("指数价格")]),_c('p',[_vm._v("$2,406.75")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',[_c('span',[_vm._v("预言机价格")]),_c('p',[_vm._v("$2,406.75")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',[_c('span',[_vm._v("未平仓合约")]),_c('p',[_vm._v("2,406.75 ETH")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',[_c('span',[_vm._v("1小时资金")]),_c('p',[_vm._v("$2,40611.75")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',[_c('span',[_vm._v("24小时交易量")]),_c('p',[_vm._v("406.75")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',[_c('span',[_vm._v("24小时交易数")]),_c('p',[_vm._v("$2,41106.75")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',[_c('span',[_vm._v("下一笔资金")]),_c('p',[_vm._v("09:12")])])])
}]

export { render, staticRenderFns }