<template>
  <div>
        <StockBar></StockBar>
        <div class="stockMain">
        <StockMainLeft></StockMainLeft>
        </div>
  </div>
</template>
<script>
import StockMainLeft from "./components/stockMainLeft.vue"
import StockBar from "./components/stockBar.vue"
export default {
  name: 'stock',
  props: {

   },
  components: {
      StockMainLeft,
      StockBar
   },
  data() {
    return {

   }
   },
  computed: {

   },
  watch: {

   },
  methods: {

   },
  created() {

   },
  mounted() {

   },
  beforeDestroy() {

   },
};
</script>
<style lang='less' scoped>
.stockMain{flex:1;display:flex; overflow:hidden;}
</style>