
<template>
<div class="stockBar">
    <div class="stockBarLeft">
        <div class="symbolPairChoose" @click="SPCselect">
            <div class="symbolPair"><img src="@/assets/img/logos/eth.svg"><span class="ml5">ETH - DAI</span></div>
            <div class="symbolPairIcon"><span class="mr5">交易对</span><Icon v-if="isSelect" type="md-arrow-dropup" /><Icon v-else type="md-arrow-dropdown" /></div>
        </div>
        <div class="SPCcomplex" v-if="isSelect">
            <div class="symbolPairChoose">
                <div class="symbolPair"><img src="@/assets/img/logos/eth.svg"><span class="ml5">ETH - DAI</span><span class="tag f12 ml5">ETH</span></div>
                <div class="symbolPairIcon">
                    <span class="mr5">$1,920.0</span>
                    <span v-if="true" class="red"><Icon type="md-arrow-dropdown" /> -4.89%</span>
                    <span v-else class="green"><Icon type="md-arrow-dropup" /> 4.89%</span>
                </div>
            </div>
            <div class="symbolPairChoose boderB" v-for="(item,index) in 10" :key="index">
                <div class="symbolPair"><img src="@/assets/img/logos/eth.svg"><span class="ml5">ETH - DAI</span><span class="tag f12 ml5">ETH</span></div>
                <div class="symbolPairIcon">
                    <span class="mr5">$1,920.0</span>
                    <span v-if="true" class="green"><Icon type="md-arrow-dropdown" /> 4.89%</span>
                    <span v-else class="red"><Icon type="md-arrow-dropup" /> -4.89%</span>
                </div>
            </div>
        </div>
    </div>
    <div class="stockBarRight">
        <div class="stockPrice green">$2,407.2</div>
        <ul class="pairInfo">
            <li>
                <div>
                    <span>指数价格</span>
                    <p>$2,406.75</p>
                </div>
            </li>
            <li>
                <div>
                    <span>预言机价格</span>
                    <p>$2,406.75</p>
                </div>
            </li>
            <li>
                <div>
                    <span>24小时变动</span>
                    <p class="red"><Icon type="md-arrow-dropdown" />$2,406.75 (20%)</p>
                </div>
            </li>
            <li>
                <div>
                    <span>未平仓合约</span>
                    <p>2,406.75 ETH</p>
                </div>
            </li>
            <li>
                <div>
                    <span>1小时资金</span>
                    <p>$2,40611.75</p>
                </div>
            </li>
            <li>
                <div>
                    <span>24小时交易量</span>
                    <p>406.75</p>
                </div>
            </li>
            <li>
                <div>
                    <span>24小时交易数</span>
                    <p>$2,41106.75</p>
                </div>
            </li>
            <li>
                <div>
                    <span>下一笔资金</span>
                    <p>09:12</p>
                </div>
            </li>
        </ul>
    </div>
</div>
</template>

<script>
export default {
    data () {
        return {
            isSelect:false,
        };
    },
    components: {
       
    },
    watch:{
       
    },
    beforeDestroy () {

    },
    computed:{
        
    },
    mounted () {
       
    },
    methods: {
        //选择交易对
        SPCselect(){
            this.isSelect = !this.isSelect;
        }
    }
};
</script>
<style lang="less" scoped>
.boderB{border: 1px solid @borderColor;}
.stockBar{ display: flex;height:48px;height:48px;border-bottom:1px solid @borderColor;
    .stockBarLeft{width:320px; position: relative;
    .ivu-select-selection{
        >input {background: #000;}
    }
        .SPCcomplex{ background: #fff;overflow: auto;position: relative;z-index:3;flex:1 50px;}
        .symbolPairChoose{.flexBetween;height:48px;padding:0 15px; cursor:pointer;z-index:auto;background: rgba(0, 0, 0, 0.87);
            &:hover{background:#000;}
            .symbolPair{.flexCenter;
                img{height:24px;width:24px;border-radius: 1000px;}
            }
            .symbolPairIcon{
                span{opacity: 0.5;}
            }
        }
        &:after{.afterLine;}
    }
    .stockBarRight{flex:1;display:flex; overflow:hidden; overflow-x:auto;
        .stockPrice{height:48px;padding:0 20px;font-size:18px;.flexCenter;font-weight:bold;position: relative;
            &:after{.afterLine;}
        }
        .pairInfo{display: flex;
            li{padding:0 20px; position: relative;height:48px;.flexCenter;font-size:12px;
                >div{white-space: nowrap;
                    >span{opacity: 0.5;}
                }
                &:after{.afterLine;}
            }
        }
    }
}
</style>