<!--
 * @Author: Tony.huang
 * @Date: 2021-06-03 15:06:53
 * @LastEditTime: 2021-06-17 15:48:34
 * @LastEditor: Tony.huang
 * @Description: 
 *  
-->
<template>
<div class="stockMainLeft">
    <div class="stockAssets">
        <div class="stockConnect">
            <div>连接您的以太坊钱包，以存入资金和开始交易。</div>
            <div class="mt10"><Button type="info">连接钱包</Button></div>
        </div>
    </div>
    <div class="stockAction">
        <div class="stockActionTab">
            <ul>
                <li @click="upMarket('market'),isMarket=false" :class="market=== 'market'?'active':''">市价</li>
                <li @click="upMarket('limit'),isMarket=false" :class="market=== 'limit'?'active':''">限价</li>
                <li  @click="upMarket('stop')" :class="market=== 'stopLimit'||market=== 'trailingStop'?'active':''">
                    <span class="mr5">止损</span><Icon v-if="isMarket" type="md-arrow-dropup" /> <Icon v-else type="md-arrow-dropdown" />         
                </li>
            </ul>
            <div v-if="isMarket" class="stopChild">
                <div @click="isMarket=false,upMarket('stopLimit')">止损限价</div>
                <div @click="isMarket=false,upMarket('trailingStop')">追踪止损</div>
            </div>
        </div>
        <div class="">
            <div class="actionTab">
                <div class="sell" @click="upDeal('buy')" :class="deal==='buy'?'bg active':''">卖出</div>
                <div class="buy" @click="upDeal('sell')" :class="deal==='sell'?'bg active':''">买入</div>
            </div>
            <div class="actionWp">
                <Tooltip max-width="250" placement="top-start">
                    <div slot="content">
                        <p class="f16">订单金额</p>
                        <p class="f12 mt5">要买入或卖出的ETH的金额。这是您在将订单全部成交时头寸将增加或减少的金额，而不是您得到的头寸金额。</p>
                    </div>
                    <div class="mt15 actionWpTitle">数量：<span class="tag">ETH</span></div>
                </Tooltip>
                <div class="mt10">
                    <Input value="" class="dark" size="large" placeholder="0.00" />
                </div>
                <div v-if="market=== 'market'">
                    <Tooltip max-width="250" placement="top-start">
                        <div slot="content">
                            <p class="f16">订单金额</p>
                            <p class="f12 mt5">要买入或卖出的ETH的金额。这是您在将订单全部成交时头寸将增加或减少的金额，而不是您得到的头寸金额。</p>
                        </div>
                        <div class="mt15 actionWpTitle">价格：<span class="tag">DAI</span></div>
                    </Tooltip>
                    <div class="mt10">
                        <Input value="" class="dark" size="large" placeholder="0.00" />
                    </div>
                </div>
                <div v-if="market=== 'limit'">
                    <Tooltip max-width="250" placement="top-start">
                        <div slot="content">
                            <p class="f16">限价</p>
                            <p class="mt5">此订单只能以指定的限价或更高的价格全部成交。如果您的订单在下单时交叉，则您的订单将以最优惠的价格全部成交任何交叉订单。</p>
                        </div>
                        <div class="mt15 actionWpTitle">限价：<span class="tag">USD</span></div>
                    </Tooltip>
                    <div class="mt10">
                        <Input value="" class="dark" size="large" placeholder="0.00" />
                    </div>
                </div>
                <div v-if="market=== 'stopLimit'">
                    <Tooltip max-width="250" placement="top-start">
                        <div slot="content">
                            <p class="f16">停损价</p>
                            <p class="mt5">当此市场的指数价格超过您的停损价时，您的止损单将转换为限价订单（止损限价订单）或作为市价订单执行（追踪止损单）。</p>
                        </div>
                        <div class="mt15 actionWpTitle">触发价格：<span class="tag">USD</span></div>
                    </Tooltip>
                    <div class="mt10">
                        <Input value="" class="dark" size="large" placeholder="0.00" />
                    </div>
                    <Tooltip max-width="250" placement="top-start">
                        <div slot="content">
                            <p class="f16">限价</p>
                            <p class="mt5">此订单只能以指定的限价或更高的价格全部成交。如果您的订单在下单时交叉，则您的订单将以最优惠的价格全部成交任何交叉订单。</p>
                        </div>
                        <div class="mt15 actionWpTitle">限价：<span class="tag">USD</span></div>
                    </Tooltip>
                    <div class="mt10">
                        <Input value="" class="dark" size="large" placeholder="0.00" />
                    </div>
                </div>
                <div v-if="market=== 'trailingStop'">
                    <Tooltip max-width="250" placement="top-start">
                        <div slot="content">
                            <p class="f16">追踪百分比</p>
                            <p class="mt5">追踪百分比设置您的停损价将“追踪”指数价格的百分比。只要指数价格变动有利于您的头寸，您订单的停损价就将根据您指定的追踪百分比进行更新。</p>
                        </div>
                        <div class="mt15 actionWpTitle">追踪百分比<span class="tag">USD</span></div>
                    </Tooltip>
                    <div class="mt10">
                        <Input value="" class="dark" size="large" placeholder="0.00" />
                    </div>
                </div>
                <div class="center mt20 pb20">
                    <Button type="info" long size="large">下市价订单</Button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data () {
        return {
            //买入卖出active
            deal:'buy',
            //市价限价损失active
            market:'market',
            isMarket:false,
        };
    },
    components: {
       
    },
    watch:{
       
    },
    beforeDestroy () {

    },
    computed:{
        
    },
    mounted () {
       
    },
    methods: {
        //买入卖出，选择active
        upDeal(event){
            this.deal = event;
        },
        //市价格 限价 损失active
        upMarket(event){
            if(event === 'stop'){
                this.isMarket = !this.isMarket;
            }else{
                this.market = event;
            }
        }
    }
};
</script>

<style lang="less" >
.stockMainLeft{width:320px;border-right:1px solid @borderColor;display:flex;flex-direction: column;
    .stockAssets{height:170px;border-bottom:1px solid @borderColor;.flexCenter;text-align:center;
        .stockConnect{margin:0 30px;}
    }
    .stockAction{flex:1;overflow:auto;
        .stockActionTab{position: relative;
            ul{display: flex;border-bottom:1px solid @borderColor;
                li{width:33.33333%;.flexCenter;height:48px;border-left:1px solid @borderColor;box-sizing:border-box; cursor:pointer;
                    &:first-child{border:0;}
                    &.active{background: #000;cursor:default;color:#fff;}
                    &:hover{background: #000;}
                }
            }
           .stopChild{ .column; position: absolute;right:0;z-index: 3;background:#fff;
               >div{ .pt10;.pb10;.pl20;.pr20; background:rgba(0, 0, 0, 0.87);border-bottom:1px solid @borderColor;
                    &.active{background: #000;cursor:default;}
                    &:hover{background: #000;}
                }
           }
        }
        .actionTab{ position:relative;
            width:280px;border-radius:@btn-border-radius ;background:#111; display: flex;margin:20px auto 0;overflow:hidden;
            >div{height:40px;width:140px;.flexCenter; position: relative;z-index:2;cursor:pointer;}
            .sell{border-radius: @btn-border-radius 0 0 @btn-border-radius;
                &.active{.red;}
            }
            .buy{border-radius: 0 @btn-border-radius @btn-border-radius 0;
                &.active{.green;}
            }
            .bg{
                border-radius:@btn-border-radius ;height:40px;width:140px;transition:ease all 0.3s;background: @graybg;display: flex;
            }
        }
        .actionWp{width:280px;margin:0 auto;font-size:13px; 
            .ivu-slider-wrap{background-color: @success-color;.mb5;
                .ivu-slider-bar{background-color: @error-color;}
                .ivu-slider-stop{background: #000;}
                .ivu-slider-button{border-color:@success-color;}
            }
            .actionWpTitle{
                &:hover{.op5;}
            }
        }
    }
}
</style>